import React, {Component} from "react";

import 'react-toastify/dist/ReactToastify.css';
import {
    apiGetHeroData, apiGetHeroTokenUserData, apiGetHeroUserData, apiGetTokenHeroData,
} from "./api/requests";
import {LoadingInline} from "./common/utility/loading";
import MapSeasonTokenHeroMintButton from "./MapSeasonTokenHeroMintButton";
import MapSeasonTokenHeroSellBackButton from "./MapSeasonTokenHeroSellBackButton";

class MapSeasonTokenHeroElement extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem('authToken');


        this.state = {
            loadingFromChain: true,
            loadingFromServer: true,
            loadingFromServerUserData: true,
            authToken: token,
            cardsMinted: 0,
        }

        this.loadCardDataFromServer = this.loadCardDataFromServer.bind(this);
        this.loadUserCardDataFromServer = this.loadUserCardDataFromServer.bind(this);
        this.loadCardDataFromChain = this.loadCardDataFromChain.bind(this);

        this.potentialPrize = this.potentialPrize.bind(this);
        this.cardsMinted = this.cardsMinted.bind(this);
        this.potentialCardPrize = this.potentialCardPrize.bind(this);
        this.currentCardPrice = this.currentCardPrice.bind(this);
        this.chainButton = this.chainButton.bind(this);
        this.userHeroShards = this.userHeroShards.bind(this);
        this.currentSellCardPrice = this.currentSellCardPrice.bind(this);



    }

    componentDidMount() {
        this.loadCardDataFromServer()
        this.loadCardDataFromChain()
        this.loadUserCardDataFromServer()
    }

    async loadCardDataFromChain() {
        this.setState({
            loadingFromChain: false,
        })
    }

    async loadCardDataFromServer() {
        await apiGetTokenHeroData(
            this.props.heroSeason.uid,
            this.props.bucketId,
        ).then(async (response) => {
            if (response.hero !== undefined && response.hero !== null) {
                this.setState({
                    heroData: {
                        tokenContract: response.hero.tournament_contract,
                        cardPrice: this.props.currentPrice,
                        cardsMinted: response.total_supply,
                    },
                    loadingFromServer: false,
                })
            }
        }).catch(e => {
            this.setState({
                heroData: {

                }
            })
            this.setState({
                loadingFromServer: true,
            })
        })
    }

    async loadUserCardDataFromServer() {
        if(this.state.authToken === null) {
            this.setState({
                loadingFromServerUserData: true,
                userCardsMinted: 0,
            })
            return
        }
        await apiGetHeroTokenUserData(
            this.state.authToken,
            this.props.heroSeason.uid,
            this.props.bucketId
        ).then(async (response) => {
            if (response.token_amount !== undefined && response.token_amount !== null) {

                this.setState({
                    userCardsMinted: response.token_amount,
                    loadingFromServerUserData: false,
                })
            }
        }).catch(e => {
            this.setState({
                heroData: {
                    userCardsMinted: 0,
                }
            })
            this.setState({
                loadingFromServerUserData: true,
            })
        })


    }

    potentialPrize() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        return <>
            {parseFloat((this.props.prizePool * this.props.deductionPrizePool).toFixed(2))} <span className={"main-color-important"}>STRK</span>
        </>
    }

    cardsMinted() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        return <>
            {this.state.heroData.cardsMinted}
        </>
    }

    potentialCardPrize() {
        if(this.state.loadingFromServer === true || this.state.loadingFromChain === true) {
            return <LoadingInline />
        }
        const shardStartSaleDate = Date.parse(this.props.seasonConfig.hero_shards_start_sale)
        const seasonEndDate = Date.parse(this.props.seasonConfig.date_end)
        const seasonStartDate = Date.parse(this.props.seasonConfig.date_start)
        if(shardStartSaleDate < Date.now() && seasonEndDate > Date.now()) {
            if(this.state.heroData.cardsMinted === 0) {
                return <>
                    <span className={"main-color-important"}>Pot.prize/card:</span> N/A <span className={"main-color-important"}>STRK</span>
                </>
            }
            return <>
                <span className={"main-color-important"}>Pot.prize/card:</span> {(this.props.prizePool * this.props.deductionPrizePool / this.state.heroData.cardsMinted).toFixed(2)} <span className={"main-color-important"}>STRK</span>
            </>
        }

    }

    currentCardPrice() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        const shardStartSaleDate = Date.parse(this.props.seasonConfig.hero_shards_start_sale)
        const seasonEndDate = Date.parse(this.props.seasonConfig.date_end)
        const seasonStartDate = Date.parse(this.props.seasonConfig.date_start)
        if(shardStartSaleDate < Date.now() && seasonEndDate > Date.now()) {
            return <>
                <span
                    className={"main-color-important"}>Card price now:</span> {parseFloat(this.state.heroData.cardPrice).toFixed(2)} <span className={"main-color-important"}>STRK</span>
            </>
        }
    }

    currentSellCardPrice() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        const shardStartSaleDate = Date.parse(this.props.seasonConfig.hero_shards_start_sale)
        const seasonEndDate = Date.parse(this.props.seasonConfig.date_end)
        const seasonStartDate = Date.parse(this.props.seasonConfig.date_start)
        if(shardStartSaleDate < Date.now() && seasonEndDate > Date.now()) {
            return <>
                <span
                    className={"main-color-important"}>Sell price now:</span> {parseFloat(this.props.buyBackPrice).toFixed(2)} <span className={"main-color-important"}>STRK</span>
            </>
        }

    }

    chainButton() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }

        const shardStartSaleDate = Date.parse(this.props.seasonConfig.hero_shards_start_sale)
        const seasonEndDate = Date.parse(this.props.seasonConfig.date_end)
        const seasonStartDate = Date.parse(this.props.seasonConfig.date_start)

        if(shardStartSaleDate < Date.now() && seasonEndDate > Date.now()) {
            return <>
                <MapSeasonTokenHeroMintButton
                    contractAddress={this.state.heroData.tokenContract}
                    id={this.props.seasonConfig.chain_tournament_id}
                    token={this.props.heroSeason.uid}
                />
                <MapSeasonTokenHeroSellBackButton
                    contractAddress={this.state.heroData.tokenContract}
                    id={this.props.seasonConfig.chain_tournament_id}
                    token={this.props.heroSeason.uid}
                />
            </>
        }
        if(seasonEndDate < Date.now()) {
            return <>

            </>
        }

        return <>

        </>
    }

    userHeroShards() {
        if(this.state.authToken === null) {
            return <></>
        }
        if(this.state.loadingFromServer === true || this.state.loadingFromServerUserData === true) {
            return <LoadingInline />
        }
        if(this.state.heroData.cardsMinted === 0) {
            return <>
                <p className={"mb-0"}><span
                    className={"main-color-important"}>My cards:</span> 0</p>
                <p className={"mb-0"}><span
                    className={"main-color-important"}>My pot.prize:</span> N/A <span className={"main-color-important"}>STRK</span></p>
            </>
        }
        return <>
            <p className={"mb-0"}><span
                className={"main-color-important"}>My cards:</span> {this.state.userCardsMinted}</p>
            <p className={"mb-0"}><span
                className={"main-color-important"}>My pot.prize:</span> {parseFloat((this.state.userCardsMinted * this.props.prizePool * this.props.deductionPrizePool / this.state.heroData.cardsMinted).toFixed(2))} <span className={"main-color-important"}>STRK</span></p>


        </>
    }

    render() {
        return <div key={this.props.heroSeason.uid}
                    className="mb-3 d-flex flex-row hero-element">
            <div
                className={"d-flex flex-column align-content-center me-3 leaderboard-table-place"}>
                <div><span>{this.props.heroSeason.place}</span></div>
                    </div>
                    <div className={"d-flex flex-column align-content-center me-3"}>
                        <img src={this.props.heroSeason.avatar}
                             className="hero-element-image rounded-5" alt={this.props.heroSeason.username}/>
                    </div>
                <div className="text-white choose-hero-card-body pe-3">
                    <h5 className="main-color-important">{this.props.heroSeason.username}</h5>
                    <p className={"mb-0"}><span
                        className={"main-color-important"}>Rating:</span> {this.props.heroSeason.points}</p>
                    <p className={"mb-0"}><span
                        className={"main-color-important"}>Potential prize:</span> {this.potentialPrize()}</p>
                    <p className={"mb-0"}><span
                        className={"main-color-important"}>Cards minted:</span> {this.cardsMinted()}</p>
                    {this.userHeroShards()}
                </div>

            <div className="text-white choose-hero-card-body">
                <h5 className="main-color-important">&nbsp;</h5>
                <p className={"mb-0"}>{this.currentCardPrice()}
                </p>
                <p className={"mb-0"}>{this.potentialCardPrize()}</p>
                <p className={"mb-0"}>{this.currentSellCardPrice()}</p>
            </div>
            <div className={"ms-auto d-flex flex-row  align-items-start "}>
                {this.chainButton()}
            </div>
        </div>

    }
}

export default MapSeasonTokenHeroElement;
