import React, {Component} from "react";

import 'react-toastify/dist/ReactToastify.css';
import {
    apiGetGlobalSeasonConfigHeroes, apiGetHeroesTokenData,
    apiGetLeaderboard, apiGetMapSeasonInfo, apiGetMapStaticSeasonConfig,
    apiGetUserInfoMany,
} from "./api/requests";
import {Loading, LoadingInline} from "./common/utility/loading";
import {delay} from "./common/utility/delay";
import CountdownLabel from "./common/countdownLabel";
import IconTitleValue from "./common/iconTitleValue";
import heroes from "./heroes.json";
import HeroTopElement from "./HeroTopElement";
import MapSeasonTokenHeroElement from "./MapSeasonTokenHeroElement";
import MapSeasonTokenHeroMintButton from "./MapSeasonTokenHeroMintButton";
import MapSeasonTokenHeroRewardButton from "./MapSeasonTokenHeroRewardButton";

class MapSeasonTokenHeroesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingTokenData: true,
            loadingSeasonTop: true,
            loading: true,
            noSeason: false,
            isHero: 1,
            heroesOn: heroes.filter(hero => hero.playable ),
            mapId : this.props.search.get("map"),
            prizePool: 0,
        }
        this.loadSeasonTop = this.loadSeasonTop.bind(this);
        this.loadingMapStaticData = this.loadingMapStaticData.bind(this);
        this.loadHeroesTokenData = this.loadHeroesTokenData.bind(this);
        this.getHeroesPrize = this.getHeroesPrize.bind(this);

    }

    async componentDidMount() {
        await this.loadingMapStaticData();
        await this.loadSeasonTop()
        await this.loadHeroesTokenData();
    }

    async loadingMapStaticData() {
        await apiGetMapStaticSeasonConfig(
            this.state.mapId
        ).then(async (response) => {

            const getPlaces = (array) => {
                let res = {}
                for (let i = 0; i < array.length; i++) {
                    res[array[i].place] = array[i].score
                }
                return res
            };

            let placeConfig = response.data.config.place_scores;
            let placeConfigHeroes = response.data.config.hero_place_scores;

            this.setState({
                config: response.data.config,
                placesScores: getPlaces(placeConfig),
                placesScoresHeroes: getPlaces(placeConfigHeroes),
            })

            this.setState({
                loading: false,
            })
        })
    }

    async loadHeroesTokenData() {
        await apiGetHeroesTokenData(this.state.config.id).then(async (response) => {
            /*
            {
  "buy_back_price": 0,
  "current_price": 0,
  "error": false,
  "prize_pool": 0,
  "success": true,
  "t": 1728412498,
  "total_supply": 0
}
             */
            this.setState({
                buyBackPrice: response.buy_back_price,
                currentPrice: response.current_price,
                prizePool: response.prize_pool,
                totalSupply: response.total_supply,
                loadingTokenData: false,
            })
        })
    }

    async loadSeasonTop() {
        await apiGetMapSeasonInfo(
            this.state.mapId,
            true,
        ).then(async (response) => {
            console.log(response)
            //await delay(1000);

            let players = response.leaderboard.users;
            const ids = players.map(x => {return x.uid});

            let infos

            const heroesOn = heroes.filter(hero => hero.playable );
            console.log(heroesOn)
            infos = heroesOn.map((x, idx) => { return {uid: x.id, username: x.heroName, avatar: x.imageUrl} });
            infos = {users: infos}

            players = players.map((x, idx) => { return {...x, ...infos.users.find(y => y.uid == x.uid) }});
            console.log("asdasd")
            console.log(players)

            let playersUid = players.map((x, idx) => { return parseInt(x.uid)});
            console.log(playersUid)
            this.setState({
                loadingSeasonTop: false,
                playersHeroes: players,
                mapStatic: response.map_static,
            })
        }).catch(e => {
            console.log(e);
            window.alerts.alert("internal error")
        })
    }

    getHeroesPrize() {
        if(this.state.loadingTokenData === true) {
            return <LoadingInline />
        }
        return parseFloat(this.state.prizePool).toFixed(2)
    }

    render() {
        if(this.state.noSeason === true) {
            return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                    <h3 className={"notransform-h3"}>We are currently doing an update, please try refreshing in 5 minutes!</h3>
                </div>
            </div>
        }
        if(this.state.loading === true || this.state.loadingTokenData === true || this.state.loadingSeasonTop === true) {
            return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                    <h1 className={"notransform-h1"}>Loading data...</h1>
                </div>
                <Loading></Loading>

            </div>

        }


        if(this.state.mapStatic === undefined || this.state.mapStatic === null) {
            return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                    <h1 className={"notransform-h1"}>Current Season Map</h1>
                </div>
                <div>
                    <p>Map not found</p>
                </div>
            </div>
        }

        let pageHeader = this.state.mapStatic.config.name;

        const shardStartSaleDate = Date.parse(this.state.mapStatic.config.hero_shards_start_sale)
        const seasonEndDate = Date.parse(this.state.mapStatic.config.date_end)
        const seasonStartDate = Date.parse(this.state.mapStatic.config.date_start)

        let rewardBtn = <></>
        let tournamentData = <>
            <div className="pt-3 pb-3">
                <div className="d-flex ">
                    <IconTitleValue
                        icon="prizePool.svg"
                        title="Heroes prize">
                        {parseFloat(this.state.prizePool).toFixed(2)} STRK
                    </IconTitleValue>
                    <IconTitleValue
                        icon="seasonEnd.svg"
                        title="Tournament ends in">
                        <CountdownLabel targetDate={new Date(this.state.config.date_end)}/>
                    </IconTitleValue>
                </div>
            </div>
        </>

        if (Date.now() > seasonEndDate) {
            rewardBtn = <MapSeasonTokenHeroRewardButton
                contractAddress={this.state.mapStatic.config.chain_tournament_contract}
                id={this.state.mapStatic.config.chain_tournament_id}
            />
            tournamentData = <>
                <div className="pt-3 pb-3">
                    <div className="d-flex ">
                        <IconTitleValue
                            icon="prizePool.svg"
                            title="Heroes prize">
                            {parseFloat(this.state.prizePool).toFixed(2)} STRK
                        </IconTitleValue>

                    </div>
                </div>
            </>;
        }

        return (
            <>
                <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="d-flex flex-row align-items-center">
                        <h1 className={"notransform-h1"}>{pageHeader}</h1>
                    </div>

                    {tournamentData}
                    <div>
                        <p>99% of STRK tokens from minting cards go to the Heroes prize pool, continuously increasing
                            it.</p>

                        <p>Prize pool distribution:</p>
                        <ul>
                            <li>1st place - 60%</li>
                            <li>2nd place - 30%</li>
                            <li>3rd place - 10%</li>
                        </ul>
                    </div>
                    {rewardBtn}

                    <div className={" justify-content-around"}>
                        {this.state.playersHeroes.map((hero, k) => {

                            const getRewarPoolDeduction = (place) => {
                                const configData = {
                                    1: 60,
                                    2: 30,
                                    3: 10,
                                    4: 0,
                                    5: 0,
                                    6: 0,
                                    7: 0,
                                    8: 0,
                                }
                                return configData[place] / 100
                            };

                            return <div key={"hero"+k} className={"rounded p-3 mb-2 m-0 m-sm-2"}>
                                <MapSeasonTokenHeroElement
                                    totalSupply={this.state.totalSupply}
                                    bucketId={this.state.config.id}
                                    heroSeason={hero}
                                    k={k}
                                    prizePool={this.state.prizePool}
                                    deductionPrizePool={getRewarPoolDeduction(hero.place)}
                                    buyBackPrice={this.state.buyBackPrice}
                                    currentPrice={this.state.currentPrice}
                                    seasonConfig={this.state.config}
                                />
                            </div>
                        })}
                    </div>

                </div>
            </>
        )
    }
}

export default MapSeasonTokenHeroesPage;
