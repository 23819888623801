import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const transformData = (data) => {
    return data.map((d, index) => {
        return {
            x: index + 1, // Индекс по оси X
            y: d.place,   // Значение по оси Y
        };
    });
};

const LineChart = ({ data }) => {
    const chartData = transformData(data);

    const options = {
        chart: {
            type: 'line',
            backgroundColor: '#13181D',
        },
        legend: {
            enabled: false, // Отключаем легенду
        },
        title: {
            text: null, // Убираем заголовок
        },
        xAxis: {
            title: {
                text: null, // Убираем название оси X
            },
            type: 'linear',
            tickInterval: 1,
            labels: {
                enabled: false,
            },
            lineColor: '#FFFFFF',
            lineWidth: 0,
            tickLength: 0,
        },
        yAxis: {
            title: {
                text: 'place',
                style: {
                    color: '#FFFFFF',
                },
            },
            reversed: true,
            tickInterval: 1,
            gridLineColor: '#2A2A2A',
            labels: {
                style: {
                    color: "#FFFFFF",
                },
                formatter: function () {
                    if(this.value !==0)
                        return `${this.value}`; // Можно добавить префикс/суффикс, например: `Step ${this.value}`
                    else
                        return "";
                },
            },
        },
        tooltip: {
            backgroundColor: '#1F1F1F',
            borderColor: '#4CAF50',
            style: {
                color: '#FFFFFF',
            },
            formatter: function () {
                return `<b>Place: ${this.y}`;
            },
        },
        plotOptions: {
            line: {
                color: '#000dff',
                marker: {
                    enabled: true,
                    fillColor: '#FFA500',
                    lineWidth: 1,
                    lineColor: '#000dff',
                },
            },
            series: {
                states: {
                    inactive: {
                        enabled: false, // Отключаем потемнение других серий при наведении
                    },
                },
            },
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: 'Данные',
                data: chartData,
            },
        ],
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default LineChart;
