import React, {Component} from "react";

import 'react-toastify/dist/ReactToastify.css';
import {
    apiGetHeroData, apiGetHeroSeasonHistory, apiGetHeroUserData, apiGetLeaderboard, apiGetUserInfoMany,
} from "./api/requests";
import {LoadingInline} from "./common/utility/loading";
import CandleStickChart from "./common/candleStickChart";
import {delay} from "./common/utility/delay";
import heroes from "./heroes.json";
import LineChart from "./common/lineChart";

class HeroPage extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem('authToken');

        this.state = {
            heroId: this.props.search.get("hero_id"),
            loadingHeroSeasonData: true,
            loadingFromServer: true,
            loadingFromServerUserData: true,
            authToken: token,
            cardsMinted: 0,
        }

        this.loadCardDataFromServer = this.loadCardDataFromServer.bind(this);
        this.loadUserCardDataFromServer = this.loadUserCardDataFromServer.bind(this);
        this.loadHeroSeasonHistoryData = this.loadHeroSeasonHistoryData.bind(this);

        this.heroSeasonHistory = this.heroSeasonHistory.bind(this);

    }

    componentDidMount() {
        this.loadCardDataFromServer()
        this.loadHeroSeasonHistoryData()
        this.loadUserCardDataFromServer()
    }

    async loadHeroSeasonHistoryData() {
        await apiGetHeroSeasonHistory(
            this.state.heroId
        ).then(async (response) => {
            console.log(response)
            if (response.data !== undefined && response.data !== null) {
                this.setState({
                    heroSeasonData: response.data.rows,
                    loadingHeroSeasonData: false,
                })
            }
        }).catch(e => {
            this.setState({
                heroSeasonData: {

                }
            })
            this.setState({
                loadingHeroSeasonData: true,
            })
        })
    }

    async loadCardDataFromServer() {

        await apiGetHeroData(
            this.state.heroId
        ).then(async (response) => {
            if (response.hero !== undefined && response.hero !== null) {
                this.setState({
                    heroData: {
                        tokenContract: response.hero.token_contract,
                        cardPrice: response.current_price,
                        cardsMinted: response.count,
                        hero: response.hero,
                    },
                })

                await apiGetLeaderboard(
                    1, 50, true
                ).then(async (response) => {
                    console.log(response)
                    await delay(1000);

                    if(response.leaderboard.users === null) {
                        this.setState({
                            players: [],
                            loadingFromServer: false,
                        })
                        return
                    }

                    let players = response.leaderboard.users;
                    const ids = players.map(x => {
                        return x.uid
                    });

                    let infos

                    const heroesOn = heroes.filter(hero => hero.playable );
                    infos = heroesOn.map((x, idx) => { return {uid: x.id, username: x.heroName, avatar: x.imageUrl} });
                    infos = {users: infos}

                    players = players.map((x, idx) => {
                        return {...x, ...infos.users.find(y => y.uid == x.uid)}
                    });

                    let heroSeasonData = players.filter(x => x.uid === this.state.heroId)[0]
                    this.setState({
                        selfSeasonData: heroSeasonData,
                        players: players,
                        loadingFromServer: false,
                    })
                }).catch(e => {
                    window.alerts.alert("internal error. please try again later")
                })

            }
        }).catch(e => {
            this.setState({
                heroData: {

                }
            })
            this.setState({
                loadingFromServer: true,
            })
        })



    }

    async loadUserCardDataFromServer() {
        if(this.state.authToken === null) {
            this.setState({
                loadingFromServerUserData: true,
                userCardsMinted: 0,
            })
            return
        }
        await apiGetHeroUserData(
            this.state.authToken,
            this.state.heroId,
        ).then(async (response) => {
            if (response.token_amount !== undefined && response.token_amount !== null) {

                this.setState({
                    userCardsMinted: response.token_amount,
                    loadingFromServerUserData: false,
                })
            }
        }).catch(e => {
            this.setState({
                heroData: {
                    userCardsMinted: 0,
                }
            })
            this.setState({
                loadingFromServerUserData: true,
            })
        })


    }

    heroSeasonHistory() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        return <div className={"d-flex flex-row justify-content-center"}>
            <div className={"w-75"}>
                <h2>Place chart</h2>
                <CandleStickChart data={this.state.heroSeasonData}/>
                <div className={"mb-3"}>&nbsp;</div>
                <LineChart data={this.state.heroSeasonData} />
            </div>
        </div>

    }

    render() {
        if (this.state.loadingFromServer) {
            return <LoadingInline />
        }
        return  <>
            <div className="ms-2 me-2 mb-3 d-flex flex-column align-content-center">

                <div className="mb-3 d-flex flex-row hero-element">
                    <div
                        className={"d-flex flex-column align-content-center me-3 leaderboard-table-place"}>
                        <div><span>{this.state.selfSeasonData.place}</span></div>
                    </div>

                    <div className={"d-flex flex-column align-content-center me-3"}>
                        <img src={this.state.heroData.hero.image_url}
                             className="hero-element-image rounded-5" alt={this.state.heroData.hero.hero_name}/>
                    </div>
                    <div className="text-white choose-hero-card-body pe-3">
                        <h5 className="main-color-important">{this.state.heroData.hero.hero_name}</h5>
                        <p className={"mb-0"}><span
                            className={"main-color-important"}>Season points:</span> {this.state.selfSeasonData.points}</p>
                    </div>

                    <div className="text-white choose-hero-card-body">
                        <h5 className="main-color-important">&nbsp;</h5>
                    </div>
                    <div className={"ms-auto d-flex flex-row  align-items-start "}>

                    </div>
                </div>
                {this.heroSeasonHistory()}
            </div>
        </>

    }
}

export default HeroPage;
