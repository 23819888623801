import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

// Функция для преобразования данных
const transformData = (data) => {
    const candlestickData = [];
    const flatPoints = [];

    data.forEach((d, index, arr) => {
        const open = index > 0 ? arr[index - 1].place : d.place;
        const close = d.place;
        const high = Math.max(open, close);
        const low = Math.min(open, close);
        const x = index + 1; // Используем целое число вместо даты

        const point = {
            x,
            open,
            high,
            low,
            close,
        };

        if (high === low) {
            flatPoints.push({
                x,
                y: close,
            });
        } else {
            candlestickData.push(point);
        }
    });

    return { candlestickData, flatPoints };
};

const CandleStickChart = ({ data }) => {
    const { candlestickData, flatPoints } = transformData(data);

    const options = {
        chart: {
            backgroundColor: "#13181D",
        },
        navigator: {
            enabled: false,
        },
        scrollbar: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
        },
        xAxis: {
            type: "linear", // Меняем тип оси X на "linear"
            tickInterval: 1,
            tickAmount: data.length,
            labels: {
                enabled: false,
            },
            lineWidth: 0,
            tickLength: 0,
        },
        yAxis: {
            title: {
                text: "place",
                style: {
                    color: "#FFFFFF",
                },
            },
            tickInterval: 1,
            reversed: true,
            gridLineColor: "#2A2A2A",
            labels: {
                style: {
                    color: "#FFFFFF",
                },
                formatter: function () {
                    if(this.value !==0)
                        return `${this.value}`; // Можно добавить префикс/суффикс, например: `Step ${this.value}`
                    else
                        return "";
                },
            },
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            backgroundColor: "#1F1F1F",
            borderColor: "#4CAF50",
            style: {
                color: "#FFFFFF",
            },
            shared: true,
            formatter: function () {
                if (this.points) {
                    let tooltip = `<span style="color:${this.points[0].color}">●</span> <b>${this.points[0].series.name}</b><br/>`;
                    this.points.forEach((point) => {
                        if (point.series.type === "candlestick") {
                            tooltip += `old: ${point.point.open}<br/>new: ${point.point.close}<br/>`;
                        } else if (point.series.type === "scatter") {
                            tooltip += `place: ${point.y}<br/>`;
                        }
                    });
                    return tooltip;
                } else {
                    return `<span>●</span> Place: ${this.y}`;
                }
            },
        },
        plotOptions: {
            series: {
                states: {
                    inactive: {
                        enabled: false, // Отключаем неактивное состояние для всех серий
                    },
                },
            },
            candlestick: {
                lineColor: "#000000",
                upColor: "#FF0000",
                color: "#00FF00",
                upLineColor: "#000000",
            },
            scatter: {
                marker: {
                    symbol: "circle",
                    radius: 3,
                    fillColor: "#FFA500",
                },
            },
        },
        series: [
            {
                type: "candlestick",
                name: "Place",
                data: candlestickData,
                dataGrouping: {
                    enabled: false,
                },
            },
            {
                type: "scatter",
                name: "No changes",
                data: flatPoints,
                marker: {
                    symbol: "circle",
                    radius: 3,
                    fillColor: "#FFA500",
                },
            },
        ],
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={options}
        />
    );
};

export default CandleStickChart;
