import React, {Component} from "react";

import 'react-toastify/dist/ReactToastify.css';
import {
    apiGetGlobalSeasonConfigHeroes,
    apiGetLeaderboard,
    apiGetUserInfoMany,
} from "./api/requests";
import {Loading} from "./common/utility/loading";
import {delay} from "./common/utility/delay";
import CountdownLabel from "./common/countdownLabel";
import IconTitleValue from "./common/iconTitleValue";
import heroes from "./heroes.json";
import HeroTopElement from "./HeroTopElement";

class HeroesTopPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            noSeason: false,
            isHero: 1,
            heroesOn: heroes.filter(hero => hero.playable ),
        }
        this.loadSeasonTop = this.loadSeasonTop.bind(this);
    }

    componentDidMount() {
        this.loadSeasonTop()
    }

    async loadSeasonTop() {
        await apiGetGlobalSeasonConfigHeroes({

        }).then(async (response) => {
            this.setState({
                config: response.data.grand_season.config,
                prizePool: response.prize_pool,

            })
            let isHero = true
            if (this.state.isHero === undefined || this.state.isHero === null) {
                isHero = false
            }
            await apiGetLeaderboard(
                1, 50, isHero
            ).then(async (response) => {
                console.log(response)
                await delay(1000);

                if(response.leaderboard.users === null) {
                    this.setState({
                        players: [],
                        loading: false,
                    })
                    return
                }

                let players = response.leaderboard.users;
                const ids = players.map(x => {
                    return x.uid
                });

                let infos
                if(this.state.isHero !== null) {
                    const heroesOn = heroes.filter(hero => hero.playable );
                    infos = heroesOn.map((x, idx) => { return {uid: x.id, username: x.heroName, avatar: x.imageUrl} });
                    infos = {users: infos}
                } else {
                    infos = await apiGetUserInfoMany(ids);
                }

                players = players.map((x, idx) => {
                    return {...x, ...infos.users.find(y => y.uid == x.uid)}
                });


                this.setState({
                    players: players,
                    //grand_season : response.data.grand_season,
                    //seasons: response.data.seasons,
                    loading: false,
                })
            }).catch(e => {
                window.alerts.alert("internal error. please try again later")
            })
        }).catch(e => {
            window.alerts.alert("We are currently doing an update, please try refreshing in 5 minutes!")
            this.setState({
                noSeason: true,
                loading: false,
            })
        })
    }

    render() {
            if(this.state.noSeason === true) {
                return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                        <h3 className={"notransform-h3"}>We are currently doing an update, please try refreshing in 5 minutes!</h3>
                    </div>
                </div>
            }
            if(this.state.loading === true) {
                return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                        <h1 className={"notransform-h1"}>Loading data...</h1>
                    </div>
                    <Loading></Loading>

                </div>

            }


        let playerField = "Player"
        let pageHeader = "Top-50 Players"
        if (this.state.isHero !== null) {
            playerField = "Hero"
            pageHeader = "Heroes Leaderboard"
        }

        return (
            <>
                <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="d-flex flex-row align-items-center">
                        <h1 className={"notransform-h1"}>{pageHeader}</h1>
                    </div>

                    <div className="pt-3 pb-3">
                        <div className="d-flex ">
                            <IconTitleValue
                                icon="prizePool.svg"
                                title="Heroes prize">
                                {this.state.prizePool} STRK
                            </IconTitleValue>
                            <IconTitleValue
                                icon="seasonEnd.svg"
                                title="Season ends in">
                                <CountdownLabel targetDate={new Date(this.state.config.date_end)}/>
                            </IconTitleValue>
                        </div>
                    </div>
                    <div>
                        <p>Heroes earn their points during tournaments based on the highest score any player achieved
                            with that hero on the tournament map.</p>

                        <p>At the end of the season, heroes will distribute their share of the prize pool evenly among
                            the holders of all cards of that hero.</p>

                        <p>Distribution of the prize pool based on the hero's place:</p>
                        <ul>
                            <li>1st - 50%</li>
                            <li>2nd - 25%</li>
                            <li>3rd - 10%</li>
                            <li>4th - 5%</li>
                            <li>5th - 4%</li>
                            <li>6th - 3%</li>
                            <li>7th - 2%</li>
                            <li>8th - 1%</li>
                        </ul>

                        <p>The price of the hero card starts at 25 STRK, increases by 1% with each purchase, and
                            decreases by 5% once a day.</p>

                        <p>99% of STRK tokens from minting cards go to the Heroes prize pool, continuously increasing
                            it.</p>

                    </div>

                    <div className={" justify-content-around"}>
                        {this.state.players.map((hero, k) => {

                            const getRewarPoolDeduction = (place) => {
                                const configData = {
                                    1: 50,
                                    2: 25,
                                    3: 10,
                                    4: 5,
                                    5: 4,
                                    6: 3,
                                    7: 2,
                                    8: 1,
                                }
                                return configData[place] / 100
                            };

                            return <div key={hero.place} className={"rounded p-3 mb-2 m-0 m-sm-2"}>
                                <HeroTopElement
                                    heroSeason={hero}
                                    k={k}
                                    prizePool={this.state.prizePool}
                                    deductionPrizePool={getRewarPoolDeduction(hero.place)}
                                />
                            </div>
                        })}
                    </div>

                </div>
            </>
        )
    }
}

export default HeroesTopPage;
