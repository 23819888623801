import React from 'react'
import { useEffect, useState } from 'react'
import { connect, disconnect } from "starknetkit"
import {InjectedConnector} from "starknetkit/injected";
import {cairo, CallData, Contract, provider, WalletAccount} from 'starknet';
import contract_abi from "./contract_abi_sesaon4_reward.json";

function HeroMintButton({contractAddress, shards}) {
    const [connection, setConnection] = useState('');
    const [account, setAccount] = useState('');
    const [address, setAddress] = useState('');

    const alert = window.alerts?.alert;
    const success = window.alerts?.success;
    const loading = window.alerts?.loading;
    const loadingStop = window.alerts?.loadingStop;

    useEffect(() => {
        const connectToStarknet = async() => {
            const { wallet } = await connect(
                {
                    modalMode: "neverAsk",
                    dappName: "FORCE PRIME HEROES",
                    connectors: [
                        new InjectedConnector({
                            options: {id: "argentX"}
                        }),
                        new InjectedConnector({
                            options: {id: "braavos"}
                        })
                    ]
                }
            )

            if (wallet && wallet.isConnected) {
                setConnection(wallet)
                setAccount(wallet.account)
                setAddress(wallet.selectedAddress)
            }
            await connectToStarknet().then((res) => {
                console.log(res)
            })
        }
    }, [])

    const connectWallet = async() => {
        const { wallet } = await connect(
            {
                dappName: "FORCE PRIME HEROES",
                connectors: [
                    new InjectedConnector({
                        options: {id: "argentX"}
                    }),
                    new InjectedConnector({
                        options: {id: "braavos"}
                    })
                ]
            }
        )
		
        if(wallet && wallet.isConnected) {
            setConnection(wallet)
            setAccount(wallet.account)
            setAddress(wallet.selectedAddress)
        }
    }

    const tokenBuyBtn = async() => {
        if (!connection) {
            await connectWallet()
        }
        const {wallet} = await connect(
            {
                modalMode: "neverAsk",
                dappName: "FORCE PRIME HEROES",
                connectors: [
                    new InjectedConnector({
                        options: {id: "argentX"}
                    }),
                    new InjectedConnector({
                        options: {id: "braavos"}
                    })
                ]
            }
        )

		
        if (wallet && wallet.isConnected) {
            setConnection(wallet)
            setAccount(wallet.account)
            setAddress(wallet.selectedAddress)

            console.log("Connected to Starknet")
            console.log(String(process.env.REACT_APP_STARKNET_RPC_URL))

            const myWalletAccount = new WalletAccount({nodeUrl: String(process.env.REACT_APP_STARKNET_RPC_URL)}, wallet);

            const tokenContract = new Contract(contract_abi, contractAddress, myWalletAccount)

            await tokenContract.get_current_price()
                .then(async (res) => {
                    const price = res
                    console.log(price)
                    console.log("shards", shards)
                    console.log("contractAddress", contractAddress)
                    await myWalletAccount.execute([
                        {
                            contractAddress: contractAddress,
                            entrypoint: 'approve',
                            calldata: CallData.compile({
                                spender: "0x0160d915570634db1afd89bb0636eaa8ad0c4df0a5d862c72d48bab2e3e3213b",
                                amount: cairo.uint256(shards),
                            }),
                        },
                        {
                            contractAddress: "0x0160d915570634db1afd89bb0636eaa8ad0c4df0a5d862c72d48bab2e3e3213b",
                            entrypoint: 'claim_single_reward',
                            calldata: CallData.compile({
                                token: contractAddress,
                            }),
                        },
                    ]).then(async (multiCall) => {
                        await provider.waitForTransaction(multiCall.transaction_hash)
                            .catch(e => {
                                console.log(e)
                            });
                    }).catch(e => {
                        console.log(e)
                    });

                })
                .catch(e => {
                    console.log(e)
                    alert("Error getting price from chain. Please try again later.");
                });


        }
    }

    return (
        <button className="fp-btn-bg-small text-black d-none d-sm-block" onClick={tokenBuyBtn}>Get reward</button>
    )
}

export default HeroMintButton