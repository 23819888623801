import React, {Component} from "react";

import 'react-toastify/dist/ReactToastify.css';
import {Loading} from "./common/utility/loading";
import heroes from "./heroes4season.json";
import HeroSeason4RewardElement from "./HeroSeason4RewardElement";

class HeroesSeason4RewardPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            noSeason: false,
            isHero: 1,
            heroesOn: heroes.filter(hero => hero.playable ),
        }
        this.loadSeasonTop = this.loadSeasonTop.bind(this);
    }

    componentDidMount() {
        this.loadSeasonTop()
    }

    async loadSeasonTop() {

    }

    render() {
            if(this.state.noSeason === true) {
                return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                        <h3 className={"notransform-h3"}>We are currently doing an update, please try refreshing in 5 minutes!</h3>
                    </div>
                </div>
            }
            if(this.state.loading === true) {
                return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                        <h1 className={"notransform-h1"}>Loading data...</h1>
                    </div>
                    <Loading></Loading>

                </div>

            }

        let pageHeader = "Season IV cards"

        return (
            <>
                <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="d-flex flex-row align-items-center">
                        <h1 className={"notransform-h1"}>{pageHeader}</h1>
                    </div>

                    <div className={" justify-content-around"}>
                        {this.state.heroesOn.map((hero, k) => {
                            return <div key={k} className={"rounded p-3 mb-2 m-0 m-sm-2"}>
                                <HeroSeason4RewardElement
                                    heroData={hero}
                                    k={k}
                                />
                            </div>
                        })}
                    </div>

                </div>
            </>
        )
    }
}

export default HeroesSeason4RewardPage;
